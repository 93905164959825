
<template>
	<div class="upperWrap Inquiry" id="Inquiry">
		<div class="subVisWrap" style="">
		<div class="innerWrap">
				<div class="form">
				<div class="inqForm al">
					<h3 class="pb20"><br><span class="b ">도입 문의 및 체험신청</span></h3>
						<div class="formBox">
							<label>신청인 정보</label>
							<input type="text" style="" class="nameInput" placeholder="이름" @keyup="noSpace" v-model="params.reqNm" maxlength="100" id="">
<!--							<input type="text" style="" class="telInput" placeholder="휴대전화" @keyup="noSpace" v-model="params.reqTel" maxlength="100" id="">-->
							<input type="text" style="" class="telInput" placeholder="연락처" @keyup="noSpace" v-model="params.reqTel" maxlength="100" id="">
						</div>
						<div class="formBox">
							<label>단지명</label>
							<input type="text" class="" placeholder="아파트(단지)명을 입력해주세요." @keyup="noSpace" v-model="params.aptNm" maxlength="100" id="">
						</div>
						<div class="formBox">
							<label>단지 정보</label>
							<input type="text" style="" class="w49p" placeholder="지역(&&시 &&구)" v-model="params.location" maxlength="100" id="">
							<input type="number" style="" class="w49p" placeholder="세대 수" v-model="params.houseCnt" maxlength="100" id="">
						</div>
						<div class="formBox">
							<label>Email</label>
							<input type="text" class="" placeholder="문서를 수신받을 이메일 주소를 알려주세요" @keyup="noSpace" v-model="params.reqEmail" maxlength="100" id="">
						</div>	
						<div class="formBox">
							<label>문의 내용</label>							
							 <textarea name="" id="" cols="30" rows="5" v-model="params.reqContent"></textarea>
						</div>
						<div class="formBox">
							<label>체험신청서 업로드 <span class="pointC">※ 체험을 원하시면 필수 업로드하세요</span></label>
							 <span class="fileWrap vm" style="">
								<input style="" type="text" class="w70p filename" readonly v-model="params.file.name">
								
								 <span class="btn btnBasic type02 sm" style="" onclick="$(this).next('input').click(); ">찾아보기</span>
								 <input name="File1" class="btnUpload" style="position:absolute;left:-9999999px;" id="File1" @change="selectFile" onclick="" type="file" >
							</span>
						</div>
						<div class="formBox">
							<label class="fa-ckbox mr20"><input type="checkbox" v-model="params.check"><span class="pl10 vb">당사의 개인정보 수집 및 이용에 동의합니다. <a href="javascript:;" onclick="$('#infoPop').toggle();" style="text-decoration: underline; color:#aaa; font-size: 0.8em;">(자세히 보기)</a></span></label>
				<div id="infoPop" style="display: none;">1. 정보수집 이용 기관명 : (주)디엑스플랫폼  <br> 2. 정보 수집, 이용범위와 사용목적 <br>         
				 * 수집 : 기관 및 단지정보, 신청자명, 연락처, 이메일, 세대수, 방문경로 <br> * 사용목적 : 프로그램 사용 신청 및 계약관련 <br>3. 수집 및 이용기간 : 해당 사업 종료시 까지 <br>4. 그 밖의 사항은 개인정보 취급방침을 준수합니다.                
				</div>
				</div>
            
						<div class="ac pt25">
							<a href="#" @click="onSubmit" class="btn btnBasic ac">상담 신청하기</a>
					</div>
				</div>
				<div class="inqForm type02">
					<h3 class="pb20">체험신청서 다운로드</h3>
					<p class="pb20">아래 체험신청서를  다운받은 후 <br> 작성하여 좌측 폼에 업로드하시면 <br> 체험등록에 대해 안내해드립니다.</p>
					<a href="/files/APTOK_DOC_experience_202201.zip" class="btn btnLineBasic ac" download><i class="fas fa-file-word mr10"></i>신청서 <span class="mOff">다운로드</span></a>
				</div>
				<div class="inqForm type02">
					<h3 class="pb20">계약서 다운로드</h3>
					<p class="pb20">계약서를 작성하여 <br><span class="pointC b">support@aprocni.com</span> 으로 보내주시면 <br> 계약 진행을 도와드립니다.</p>
					<a href="#" class="btn btnLineBasic ac"><i class="fas fa-file-word mr10"></i>계약서<span class="mOff"> 다운로드</span></a>
				</div>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
import { reactive } from '@vue/reactivity'
import axios from 'axios';


export default {
  name: 'Inquiry',

  setup(){
	  const params = reactive({
		aptNm : '',
		location : '',
		houseCnt : 0,
		reqNm : '',
		reqTel : '',
		reqEmail : '',
		reqContent : '',
		check : false,
		file : {}
	  });


	const selectFile = (event) => {
		console.log("file : ", event.target.files[0]);
		params.file = event.target.files[0];
		
	}

	const validation = () => {
		let result = true;

		if(!params.check){
			alert("개인정보 수집 및 이용에 동의 후 상담 신청이 가능합니다.");
			return false;
		}
		if(params.reqNm == ""){ 
			alert("신청인 이름을 입력해 주세요");
			return false;
		}

		/* // 체험단지 생성시 휴대폰번호 입력 필수 처리시 해당 적용
		if(params.reqTel == ""){
			alert("신청인 휴대전화를 입력해 주세요.");
			return false;
		}

		const checkPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/.test(params.reqTel.replaceAll("-",""));
		if(!checkPhone){
			alert("유효하지 않은 휴대전화 형식입니다.");
			return false;
		}
		*/

		if(params.reqTel == ""){
			alert("신청인 연락처를 입력해 주세요.");
			return false;
		}

		const checkPhone = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(params.reqTel.replaceAll("-",""));

		if(!checkPhone){
			alert("유효하지 않은 전화번호 형식입니다.");
			return false;
		}

		if(params.aptNm == ""){
			alert("단지명을 입력해 주세요.");
			return false;
		}

		if(params.reqEmail == ""){
			alert("문서를 수신받을 이메일을 입력해 주세요.");
			return false;
		}

		const checkEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(params.reqEmail);

		if(!checkEmail){
			alert("유효하지 않은 이메일 형식 입니다.");
			return false;
		}
		

		

		return result;
	}

	const onSubmit = async() => {

		const check = validation();

		if(check){

			const formData = new FormData();


			const data = {...params, file : null};
			formData.append('file', params.file);
			formData.append('params', JSON.stringify(data));


			console.log("params :::", params);
			const a = await axios.post("/api/regist", formData);
			//const a = axios.get("/api/test");
	
			console.log("a : ",a);

			if(a.data.status=="OK"){
				alert("상담 신청이 완료 되었습니다.");

			}else{
				alert("잠시 후 다시 시도해 주세요.");
			}
		}

	}

 	const noSpace = (event) => {
		 const str_space = /\s/;
		 if(str_space.exec(event.target.value)){
			 alert("공백을 사용할 수 없습니다.");
			 event.target.focus();
			 event.target.value = event.target.value.replaceAll(" ", "");
			 return false;
		 }
	}

	return {params, selectFile, onSubmit, noSpace};

  }


 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

